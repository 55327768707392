<template>
  <div class="container h-full p-8  mb-16 dark:text-white transition-all">
    <!-- Header Section -->
    <header class="text-center">
      <div
        v-if="open"
        class="fixed w-full h-[100vh] top-0 left-0 z-999"
        :class="open ? '' : 'hidden'"
        style="background-color: rgba(0, 0, 0, 0.5)"
      >
        <div class="w-full h-full flex justify-center items-center">
          <div class="relative px-4 w-full max-w-2xl h-auto">
            <button
              class="absolute right-0 -top-4 bg-slate-600 p-[2px] rounded z-[9999]"
              :onclick="openModal"
            >
              <i class="text-slate-300 p-4">X</i>
            </button>
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div class="flex justify-between items-start p-0">
                <iframe
                  width="750"
                  height="400"
                  v-if="open"
                  :src="videoUrl"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1
        class="text-4xl font-extrabold uppercase text-blue-700 dark:text-blue-400"
      >
        Design Like a
        <span class="text-pink-500 dark:text-pink-300">Pro Yourself</span>
      </h1>
      <p class="mt-4 text-lg text-gray-500 dark:text-gray-300">
        Create your Cover Art with Presets, Layouts, Images Filters, Fonts, and
        Overlays
      </p>
      <p class="mt-2 text-sm text-gray-400 dark:text-gray-500">
        Personalize your PlayTuneMusic Cover Art while uploading your track.
      </p>
    </header>

    <!-- Cover Art Editor Section -->
    <section
      class="my-12 py-6 flex flex-col md:flex-row items-center justify-center"
    >
      <!-- Cover Preview -->
      <div
        class="relative bg-gray-100 dark:bg-gray-800 w-full max-w-md rounded-lg shadow-lg overflow-hidden"
      >
        <img
          :src="promation_img"
          alt="Cover Art Preview"
          class="w-full h-full object-cover"
        />
      </div>

      <!-- Tools Panel -->
      <div
        class="grid grid-cols-3 gap-4 mt-6 md:mt-0 md:ml-8 p-4 bg-gray-200 dark:bg-gray-800 rounded-lg shadow-lg max-w-md w-full"
      >
        <button
          class="bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 dark:hover:bg-gray-600 p-2 rounded-lg text-sm"
        >
          Presets
        </button>
        <button
          class="bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 dark:hover:bg-gray-600 p-2 rounded-lg text-sm"
        >
          Images
        </button>
        <button
          class="bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 dark:hover:bg-gray-600 p-2 rounded-lg text-sm"
        >
          Filters
        </button>
        <button
          class="bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 dark:hover:bg-gray-600 p-2 rounded-lg text-sm"
        >
          Overlays
        </button>
        <button
          class="bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 dark:hover:bg-gray-600 p-2 rounded-lg text-sm"
        >
          Fonts
        </button>
        <button
          class="bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 dark:hover:bg-gray-600 p-2 rounded-lg text-sm"
        >
          Layouts
        </button>
      </div>
    </section>

    <!-- Footer Section -->
    <footer
      class="mt-12 flex flex-col md:flex-row justify-center items-center md:space-y-0 md:space-x-4"
    >
      <Button
        class="bg-red-500 hover:bg-red-600 text-white px-6 py-3 rounded-full text-sm font-semibold my-2 dark:bg-red-600 dark:hover:bg-red-700"
      >
        Sign Up Now
      </Button>

      <Button
        :routeBlock="false"
        :onclick="openModal"
        class="bg-red-500 hover:bg-red-600 text-white px-6 py-3 rounded-full text-sm font-semibold my-2 dark:bg-red-600 dark:hover:bg-red-700"
        gradient="bg-gradient-to-l from-yellow-400 via-orange-500 to-red-600"
        hover-shadow="hover:shadow-orange-500/50"
      >
        Watch Video
      </Button>
    </footer>
  </div>
</template>

<script setup>
import promation_img from "@/assets/images/bg_p.jpg";
import Button from "@/components/button.vue";
import { onMounted, ref } from "vue";

import * as feather from "feather-icons";

onMounted(() => {
  feather.replace();
});

const open = ref(false);
const videoUrl = ref("https://www.youtube.com/embed/GLA3gMO25Vw");

function openModal() {
  if (open.value) {
    open.value = false;
  } else {
    open.value = true;
  }
  // console.log(open.value);
}
</script>

<style>
/* Additional Styles */
</style>
