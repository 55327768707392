// src/stores/customerStore.ts
import { defineStore } from "pinia";
import axios from "axios";
import type { Customer } from "@/types/customer";

export const useCustomerStore = defineStore("customer", {
  state: () => ({
    customers: [] as Customer[],
    loading: false,
    error: "" as string | null,
    appUrl:process.env.VUE_APP_API_URL_MAIN
  }),
  actions: {
    async fetchCustomers() {
      this.loading = true;
      this.error = null;
      try {
        // Adjust the endpoint as necessary

        const response = await axios.get(
          process.env.VUE_APP_API_URL + "/customers"
        );
        // If your API returns an object, adjust accordingly. Here we assume an array.
        this.customers = response.data.data;
        // console.log("this.customers");
        // console.log("this.customers");
        // console.log("this.customers");

        // console.log(response.data);

      } catch (error: any) {
        this.error = error.message || "Error fetching customers";
      } finally {
        this.loading = false;
      }
    },
  },
});
