<template>
  <div
    class="container flex items-center justify-center  mt-8 transition-colors duration-300 bg-[#FBFCFD] dark:bg-[#121A2E]"
  >
    <div class="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
      <h1
        class="mb-4 text-3xl font-bold text-center text-gray-800 dark:text-[#FBFCFD]"
      >
        FIND THE RIGHT PLAN FOR YOUR NEEDS
      </h1>
      <p class="mb-12 text-center text-gray-500 dark:text-gray-400">
        We offer affordable and flexible pricing plans tailored to meet the
        needs of independent artists, labels, and creators.
      </p>

      <div class="flex justify-center mb-6">
        <label class="flex items-center">
          <!-- <input type="checkbox" @click="priceInSt.ChangeYearly()" class="hidden" /> -->
          <!-- <div
                        class="relative inline-flex items-center w-16 h-8 transition-colors bg-gray-300 rounded-full cursor-pointer dark:bg-gray-600">
                        <span
                            class="absolute w-6 h-6 transition-transform transform bg-white rounded-full left-1 dark:bg-[#121A2E]"
                            :class="{ 'translate-x-8': priceInSt.isYearly }"></span>
                    </div> -->
          <!-- <span class="ml-4 text-gray-800 dark:text-[#FBFCFD]">
                        {{ priceInSt.isYearly ? "Yearly" : "Monthly" }}
                    </span> -->
        </label>
      </div>

      <!-- Cards: Responsive and Content-Based Height -->
      <div
        class="flex flex-wrap justify-center md:space-x-6 space-y-6 md:space-y-0"
      >
        <div
          v-for="(plan, index) in pricedata"
          :key="index"
          class="p-6 w-[350px] transition duration-500 rounded-3xl hover:shadow-xl hover:shadow-slate-100 hover:border dark:hover:border-red-500 dark:hover:shadow-slate-800"
          :class="
            !plan.active
              ? 'shadow-xl shadow-slate-100 dark:shadow-slate-800'
              : 'hover:shadow-xl hover:shadow-slate-100 border border-orange-200 dark:border-red-500 dark:hover:shadow-slate-800'
          "
        >
          <!-- Title -->
          <h2 class="mb-4 text-xl font-bold text-gray-800 dark:text-[#FBFCFD]">
            {{ plan.title }}
          </h2>

          <!-- Description -->
          <p class="mb-4 text-gray-500 dark:text-gray-400 ">
            {{ plan.description }}
          </p>

          <!-- Price -->
          <div
            class="mb-4 text-4xl font-extrabold text-gray-800 dark:text-[#FBFCFD]"
          >
            ${{
              priceInSt.isYearly
                ? parseInt(plan.yearly_price).toString()
                : parseInt(plan.monthly_price).toString()
            }}
            <!-- <span class="text-lg text-gray-500 dark:text-gray-400">{{ priceInSt.isYearly ? "/year" :
                            "/month" }}</span> -->
          </div>

          <!-- Features -->
          <ul class="mb-6 space-y-2 text-gray-500 dark:text-gray-400">
            <li
              v-for="(feature, idx) in plan.showAll
                ? plan.features
                : plan.features.slice(0, 6)"
              :key="idx"
              class="flex items-center"
            >
              <svg
                class="w-5 h-5 mr-2 text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              {{ feature.title }}
            </li>
          </ul>

          <!-- See All Button -->
          <!-- <div v-if="plan.features.length > 6">
                        <button @click="toggleFeatures(index)"
                            class="w-[100px] float-right px-4 py-2 text-sm font-bold text-right text-blue-500 bg-transparent rounded-full   ">
                            {{ plan.showAll ? "See Less" : "See All" }}
                        </button>
                    </div> -->

          <!-- Button -->
          <div class="mt-4">
            <a :href="ftext + plan.title + ltext" target="_blank">
              <Buttonx routeBlock="false" size="sm">
                {{ plan.title === "API PLAN" ? "Contact us" : "Get started" }}
              </Buttonx>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
let ftext = "https://wa.me/8801716255538?text=i want to buy ";
let ltext = " plan";

const whatsappUrl = (msg) => {
  const baseUrl = "https://wa.me/8801716255538";
  return `${baseUrl}?text=i want to buy ${msg} plan`;
};
import Buttonx from "@/components/button.vue";

// export default {
//     components: {
//         Buttonx,
//     },

//     data() {
//         return {
//             isYearly: false,
//             pricingPlans: [
//                 {
//                     name: "ARTIST PLAN",
//                     description: "Best option for unlimited music distribution and artist management.",
//                     monthlyPrice: 0,
//                     yearlyPrice: 0,
//                     showAll: false,
//                     active: true,
//                     features: [
//                         "Unlimited Distribution",
//                         "Unlimited Releases",
//                         "Artist Dashboard",
//                         "YouTube Official Artist Channel",
//                         "Spotify Verified Artist Checkmark",
//                         "Apple Music Verified Artist Checkmark",
//                         "Release Identification for YouTube, Meta, and TikTok",
//                         "MP3 Support",
//                         "Release Approval Time: 24–72 Hours",
//                         "Vevo Distribution",
//                         "Ringtone Distribution",
//                         "Lyrics Distribution",
//                         "Monthly Official Sales Reports",
//                         "Customer Service Response Time: 24 Hours",
//                         "Song Transfer",
//                         "24/7 Customer Support",
//                         "DMCA Protection",
//                         "Free Content ID",
//                         "Cover Song Distribution",
//                         "Customized iTunes Pricing"
//                     ]
//                 },
//                 {
//                     name: "LABEL PLAN",
//                     description: "Ideal for labels managing multiple artists and releases with advanced tools.",
//                     monthlyPrice: 99,
//                     yearlyPrice: 79,
//                     showAll: false,
//                     active: false,
//                     features: [
//                         "Sub-Label Dashboard",
//                         "Unlimited Artists",
//                         "Unlimited Releases",
//                         "Distribution to All Social Media Platforms",
//                         "YouTube Official Artist Channel",
//                         "Spotify Verified Artist Checkmark",
//                         "Apple Music Verified Artist Checkmark",
//                         "Content Identification for YouTube, Meta, and TikTok",
//                         "Release Approval Time: 24–48 Hours",
//                         "Vevo Distribution",
//                         "Ringtone Distribution",
//                         "Dedicated Label Manager",
//                         "Monthly Official Sales Reports",
//                         "Daily Trend Reports",
//                         "Custom Label Name",
//                         "Custom P Line",
//                         "Custom C Line",
//                         "Song Transfer to Dashboard",
//                         "24/7 Customer Support",
//                         "DMCA Protection",
//                         "Free Content ID",
//                         "Cover Song Distribution",
//                         "Customized iTunes Pricing",
//                         "Free UPC & ISRC Codes",
//                         "Store Automator",
//                         "Cover Art Generator",
//                         "MP3 Support",
//                         "Audio & Video Converter",
//                         "Preserve Link",
//                         "Lyrics Distribution"
//                     ]
//                 },
//                 {
//                     name: "DISTRIBUTOR PLAN",
//                     description: "Comprehensive plan for managing labels and artists with unlimited tools.",
//                     monthlyPrice: 199,
//                     yearlyPrice: 149,
//                     showAll: false,
//                     active: true,
//                     features: [
//                         "Master Dashboard",
//                         "Unlimited Artists",
//                         "Unlimited Sub-Labels",
//                         "Unlimited Distribution",
//                         "Distribution to All Social Media Platforms",
//                         "YouTube Official Artist Channel",
//                         "Spotify Verified Artist Checkmark",
//                         "Apple Music Verified Artist Checkmark",
//                         "Content Identification for YouTube, Meta, and TikTok",
//                         "Release Approval Time: 24–48 Hours",
//                         "Dedicated Label Manager",
//                         "Vevo Distribution",
//                         "Ringtone Distribution",
//                         "Monthly Official Sales Reports",
//                         "Daily Trend Reports",
//                         "Custom P Line",
//                         "Custom C Line",
//                         "Song Transfer to Dashboard",
//                         "24/7 Customer Support",
//                         "DMCA Protection",
//                         "Free Content ID",
//                         "Cover Song Distribution",
//                         "Customized iTunes Pricing",
//                         "Free UPC & ISRC Codes",
//                         "Store Automator",
//                         "Cover Art Generator",
//                         "MP3 Support",
//                         "Audio & Video Converter",
//                         "Preserve Link",
//                         "Free Promotion",
//                         "Lyrics Distribution"
//                     ]
//                 },
//                 {
//                     name: "API PLAN",
//                     description: "Seamless integration with automated distribution and real-time updates. Contact us for access.",
//                     monthlyPrice: 0,
//                     yearlyPrice: 0,
//                     active: true,
//                     showAll: false,
//                     features: [
//                         "API Access",
//                     ]
//                 },
//             ],
//         };
//     },
//     methods: {
//         toggleFeatures(index) {
//             this.pricingPlans[index].showAll = !this.pricingPlans[index].showAll;
//         },
//     },
// };

import { onMounted, computed } from "vue";
import { priceStore } from "@/stores/priceStore";
import { useGlobalStore } from "@/stores/globalStore";
const globalStore = useGlobalStore();
// Access the Pinia store
const priceInSt = priceStore();

// Fetch data when the component is mounted
onMounted(async () => {
  try {
    globalStore.setLoading(true); // Start loading
    await priceInSt.fetchPricePlanData();
  } finally {
    globalStore.setLoading(false); // Stop loading
  }
});

// Reactive data from the store
const pricedata = computed(() =>
  priceInSt.PricePlanData.filter((card) => card.plan_type === "card_plan")
);
const isYearly = computed(() => priceInSt.isYearly);
// console.log("privcPlanData");
// console.log(pricedata.value);
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
