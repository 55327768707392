<template>
  <div class="container relative">
    <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
      <div class="lg:col-span-7 md:col-span-6">
        <img :src="contactImg" alt="" />
      </div>

      <div class="lg:col-span-5 md:col-span-6">
        <div class="lg:ms-5">
          <div
            class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6"
          >
            <h6
              class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3"
            >
              Contact Us
            </h6>
            <h4
              class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold"
            >
              Drop Us a Line !
            </h4>

            <div>
              <div class="grid lg:grid-cols-12 gap-3">
                <div class="lg:col-span-12">
                  <div class="text-start">
                    <label for="email" class="font-medium">Your Email:</label>
                    <input
                      name="email"
                      id="email"
                      v-model="email"
                      type="email"
                      class="form-input mt-1 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-red-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"
                      placeholder="Email "
                    />
                  </div>
                </div>

                <div class="lg:col-span-12">
                  <div class="text-start">
                    <label for="comments" class="font-medium"
                      >Your Question:</label
                    >
                    <textarea
                      name="comments"
                      id="comments"
                      v-model="body"
                      class="form-input mt-1 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-red-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"
                      placeholder="Question"
                    ></textarea>
                  </div>
                </div>

                <div class="lg:col-span-12">
                  <button
                    type="submit"
                    @click="sendEmail"
                    class="py-2 px-5 tracking-wider inline-flex items-center justify-center font-medium rounded bg-red-500 text-white"
                  >
                   {{ emailStore.isState ? "Sending..." : "Send Message"}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->
</template>

<script setup>
import contactImg from "@/assets/images/contact.png";
import { ref } from "vue";
import { useEmailStore } from "@/stores/EmailStore";

const emailStore = useEmailStore();

const email = ref("");
const subject = ref("");
const body = ref("");
 


const sendEmail = async () => {
  // Basic email validation regex
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email.value || !emailPattern.test(email.value)) {
    alert("Please provide a valid email address.");
    return;
  }

  if (body.value.trim().length < 2) {
    alert("Please provide a valid question.");
    return;
  }

  try {
    emailStore.isState = true;
    await emailStore.sendEmail({
      name: email.value.split("@")[0],
      email: email.value,
      question: body.value.trim(),
    });
    email.value = "";
    body.value = "";
    emailStore.isState = false;
    alert("Message send successfully!");
  } catch (error) {
    console.error("Error sending Message:", error);
    alert("Failed to send Message. Please try again.");
  }
};
</script>
