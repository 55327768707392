<template>
  <div>
    <Navbar :playBtn="false" :bg="true" />

    <div class="container mt-20 ">
      <div class="  ">
        <!-- Blog Header -->
        <div class="text-start p-4">
          <h1 class="text-3xl font-bold">{{ Blogs.headline }}</h1>
          <p class="text-grey-600 dark:text-gray-50">{{ formatDate(Blogs.created_at) }}</p>
        </div>

        <!-- Blogs Image -->
        <div class="w-3/4   mx-auto">
          <img :src="Blogs.image != null ? (BlogStore.appUrl + '/public' + Blogs.image.file_url) : ''"
            :alt="Blogs.headline" class=" w-full object-cover rounded-lg " />
        </div>

        <!-- Blogs Content -->
        <p class="text-lg p-6 leading-relaxed text-grey-600 dark:text-gray-50">{{ Blogs.blogs }}</p>

        <!-- Blog Parts Section -->


        <div class="flex flex-wrap align-center">
          <div v-for="part in Blogs.blog_parts" :key="part.id"
            class="p-4 rounded-lg my-6 shadow-sm flex flex-col">
            <img :src="part.blog_image != null ? (BlogStore.appUrl + '/public' + part.blog_image.file_url) : ''"
              :alt="part.title" class=" w-full lg:w-2/3 object-cover p-4 rounded-md mx-auto" />
            <div class="d p-4 lg:px-10">
              <h3 class="mt-2 text-xl font-bold text-grey-600 dark:text-gray-50">{{ part.title }}</h3>
              <p class="text-grey-600 dark:text-gray-50 mt-1">{{ part.blogs }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Contact />
    <FooterOne />
    <ScrollToTop />
    <WhatsappAction />

    <!-- <Switcher /> -->
  </div>
</template>
<script setup>
import WhatsappAction from "@/components/WhatsappAction.vue";

import FooterOne from "@/components/footer-one.vue";
import Navbar from "@/components/navbar.vue";
import Contact from "@/components/contact.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";
import Switcher from "@/components/switcher.vue";

import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

import { useBlogStore } from "@/stores/blogStore";
import { useGlobalStore } from "@/stores/globalStore";
const globalStore = useGlobalStore();

// Access the Pinia store
const BlogStore = useBlogStore();

// Fetch data when the component is mounted
onMounted(async () => {
  // console.log(route.params.id);
  const blogId = route.params.id;
  globalStore.setLoading(true); // Start loading
  await BlogStore.SingleBlogFatch(blogId);
  globalStore.setLoading(false); // Start loading
});

const Blogs = computed(() => BlogStore.SingleBlogs);

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options).replace(",", "");
}
</script>

<style></style>
