<template>
  <!-- Start Footer -->
  <div class="fotter">
    <section class="md:px-6 py-12 bg-[#121A2E]">
      <div class="flex flex-col items-center justify-center p-20 px-4">
        <h1 class="text-3xl font-bold text-gray-900 dark:text-white mb-2">
          Stay Connected with Us
        </h1>
        <p class="text-gray-600 text-center mb-6 max-w-[800px]">
          Subscribe to our newsletter and be the first to receive exclusive
          updates, special offers, and the latest news straight to your inbox.
          Sign up today and never miss a beat! We promise to keep your inbox
          inspiring, not overwhelming.
        </p>
        <div
          class="flex items-center space-x-2 w-full max-w-md border border-gray-400 dark:container rounded-lg focus:outline-none p-1"
        >
          <input
            type="email"
            v-model="email"
            placeholder="Enter your email"
            class="w-full p-1 dark:text-white dark:bg-slate-900 rounded-lg focus:outline-none focus:ring-black dark:focus:ring-white"
          />
          <button
            @click="sendEmail"
            class="bg-black dark:bg-white text-white dark:text-black px-3 py-2 text-sm md:text-md rounded-lg hover:bg-gray-800"
          >
            Subscribe
          </button>
        </div>
      </div>
    </section>
    <footer class="py-10 text-gray-300 bg-gray-900">
      <div class="container mx-auto">
        <div class="grid grid-cols-12 gap-8">
          <!-- Logo and Description -->
          <div class="col-span-12 md:col-span-4">
            <router-link to="#" class="logo-footer">
              <img :src="logo" class="mb-4 w-36" alt="Logo" />
            </router-link>
            <p class="text-sm leading-relaxed">
              Get your sound heard across the global. Simplify your distribution
              process and connect with millions of listeners on every major
              streaming service. Let your talent shine, starting today.
            </p>
          </div>

          <!-- Links Section -->
          <div class="col-span-12 md:col-span-8 text-start items-start">
            <div class="grid grid-cols-3 gap-8">
              <div>
                <h6 class="mb-4 text-lg font-semibold">Service</h6>
                <ul class="space-y-2 text-sm">
                  <li class="ml-1">
                    <router-link
                      to="/music-distribution"
                      class="hover:text-white"
                    >
                      Music Distribution</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/video-distribution"
                      class="hover:text-white"
                      >Video Distribution</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/rights" class="hover:text-white"
                      >Rights Management</router-link
                    >
                  </li>
                  <li class="">
                    <router-link to="/monetization" class="hover:text-white"
                      >YouTube Monetization</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/store" class="hover:text-white"
                      >Store</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/contact" class="hover:text-white"
                      >MCN/CMS</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="">
                <h6 class="mb-4 text-lg font-semibold">Community</h6>
                <ul class="space-y-2 text-sm">
                  <li class="ml-1">
                    <router-link to="/blog" class="hover:text-white"
                      >Blog</router-link
                    >
                  </li>
                  <li class="">
                    <a
                      href="https://facebook.com/playtunemusic.net"
                      target="_blank"
                      class="hover:text-white"
                    >
                      Facebook</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/playtunemusicbd?igsh=MTgzODFoc3phZ2l3Zw=="
                      target="_blank"
                      class="hover:text-white"
                      >Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      t
                      href="https://youtube.com/@playtunemusicbd?si=msJrV0p2QftzRnXN"
                      target="_blank"
                      class="hover:text-white"
                    >
                      Youtube
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="https://linkedin.com/in/playtune-music-42b75b17b"
                      target="_blank"
                      class="hover:text-white"
                      >Linkedin
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h6 class="mb-4 text-lg font-semibold">Support</h6>
                <ul class="space-y-2 text-sm">
                  <li class="ml-1">
                    <router-link to="/contact" class="hover:text-white"
                      >Contact</router-link
                    >
                  </li>
                  <li>
                    <a
                      href="https://app.playtunemusic.com/login"
                      target="_blank"
                      class="hover:text-white"
                      >Client Login
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://psign.playtunemusic.com/"
                      target="_blank"
                      class="hover:text-white"
                      >Agreement</a
                    >
                  </li>
                  <li>
                    <router-link to="/privacy-policy" class="hover:text-white"
                      >Privacy Policy</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-6 mt-8 text-sm text-center border-t border-gray-700">
          © 2025 PlayTuneMusic Pvt LTD
          <!-- <p class="text-slate-400 text-[12px]">
          developed by
          <a
            href="https://russell.ruxzom.com"
            target="_blank"
            class="text-red-500 hover:underline"
            >RuxzomSoftware</a
          >
        </p> -->
        </div>
      </div>
    </footer>
  </div>
  <!-- End Footer -->
</template>

<script setup>
import { ref } from "vue";
import logo from "@/assets/images/logo-light.png";
import { useEmailStore } from "@/stores/EmailStore";
const emailStore = useEmailStore();

const email = ref("");
const year = ref(new Date().getFullYear());

const sendEmail = async () => {
  // Basic email validation regex
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email.value || !emailPattern.test(email.value)) {
    alert("Please provide a valid email address.");
    return;
  }

  try {
    emailStore.isState = true;
    await emailStore.sendEmail({
      name: email.value.split("@")[0],
      email: email.value,
      question: "i want to subscribe on PlayTuneMusic blog",
    });
    email.value = "";
    emailStore.isState = false;
    alert("Subscribe successfully!");
  } catch (error) {
    console.error("Error to subscribe ", error);
    alert("Failed to Subscribe. Please try again.");
  }
};
</script>
