<template>
  <section class="conatiner py-28 px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <!-- Section Heading -->
      <div class="text-center mb-8">
        <p
          class="text-sm uppercase tracking-widest text-indigo-600 dark:text-indigo-400 font-semibold"
        >
          Team Work
        </p>
        <h2
          class="mt-4 text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl"
        >
          Our Professional Team
        </h2>
        <p
          class="mt-4 text-lg text-gray-500 dark:text-gray-400 max-w-2xl mx-auto"
        >
          Our dedicated team of experts brings years of industry experience to
          ensure top-quality service. Trust us to bring your vision to life with
          professionalism and expertise.
        </p>
      </div>

      <div class="seo flex flex-col text-start pb-8">
        <div class="container mx-auto flex flex-col lg:flex-col items-center">
          <!-- Left Content -->
          <div class="flex-1 mb-8 overflow-hidden">
            <img
              :src="
                cp_partner.appUrl +
                '/public' +
                `${cpPartnerdata != null ? cpPartnerdata.profile.file_url : ''}`
              "
              alt="Feature Image"
              class="h-48 md:h-52 w-full mx-auto rounded-full"
            />
          </div>
          <div class="sp md:w-[100px]"></div>

          <!-- Right Content -->
          <div class="flex-1 text-center lg:text-left">
            <h2
              class="lg:leading-normal leading-normal text-xl md:text-4xl font-bold mb-2 text-transparent bg-clip-text animate-gradient"
              style="
                background-image: linear-gradient(90deg, #ff5733, #fadb10ff);
              "
            >
              {{ cpPartnerdata != null ? cpPartnerdata.name : "" }}
            </h2>
            <p class="text-sm md:text-xl text-gray-500 dark:text-gray-300 mb-8">
              {{ cpPartnerdata != null ? cpPartnerdata.title : "" }}
            </p>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-6">
        <div class="tiny-three-item-2">
          <div
            v-for="(item, index) in cpPartnerdata_All"
            :key="index"
            class="tiny-slide text-center"
          >
            <div class="text-start">
              <div
                class="relative w-72 mx-auto mb-6 rounded-md bg-gray-200 dark:bg-gray-500 overflow-hidden transition-transform duration-300 hover:scale-105"
              >
                <!-- Replace with actual image -->
                <div class="w-full h-56 bg-gray-200 dark:bg-gray-500">
                  <img
                    :src="cp_partner.appUrl + '/public' + item.profile.file_url"
                    class="w-full h-full object-cover rounded-b-neumorphism"
                    srcset=""
                  />
                </div>
                <!-- <button
                  @click="handleFacebookClick"
                  class="absolute bottom-20 right-2 bg-white hover:bg-green-100 text-gray-700 rounded-full shadow-lg dark:shadow-gray-800 focus:outline-none"
                  aria-label="Visit our Facebook page"
                >
                
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M22 12a10 10 0 1 0-11.5 9.9v-7h-2v-3h2v-2.3c0-2 1.2-3.1 3-3.1.9 0 1.8.2 1.8.2v2h-1c-1 0-1.3.6-1.3 1.2v1.7h2.2l-.3 3h-1.9v7A10 10 0 0 0 22 12z"
                    />
                  </svg>
                </button> -->
                <div class="content p-4">
                  <h3
                    class="text-xl font-bold text-gray-900 dark:text-white mb-2"
                  >
                    {{ item.name }}
                  </h3>
                  <p class="text-gray-600 dark:text-gray-300">
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TeamSection",
};
</script>

<script setup>
import dm from "@/assets/logo-icon.png";
import seo_bg from "@/assets/ceo_bg.png";

import { onMounted, ref, computed } from "vue";
import { tns } from "tiny-slider";

import { useCpPartnerStore } from "@/stores/cp_partnerStore";
const cp_partner = useCpPartnerStore();

const cpPartnerdata = computed(() => cp_partner.cp_partner[0]);
const cpPartnerdata_All = computed(() => cp_partner.cp_partner.slice(1));

onMounted(async () => {
  await cp_partner.fetchcp_partner();
  // console.log("lientdata.value.length");
  // console.log(cpPartnerdata.value);
  // console.log(cpPartnerdata.value.length);

  tns({
    container: ".tiny-three-item-2",
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
      992: {
        items: 3,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  });
});
</script>
