<template>
  <!-- The link below can be customized with your WhatsApp number or URL -->
  <a
    :href="whatsappUrl"
    target="_blank"
    rel="noopener noreferrer"
    class="fixed bottom-16 right-10 z-50 bg-green-500 hover:bg-green-600 text-white rounded-full w-12  md:w-16 h-12 md:h-16 flex items-center justify-center shadow-lg"
  >
    <!-- WhatsApp SVG Icon -->
     <img :src="this.wp" alt="logo" class="p-[10px] md:p-[12px]"  >
  </a>
</template>

<script >
import { defineComponent, computed } from "vue";
import wp from "@/assets/whatsapp.png" ;
export default defineComponent({
  name: "WhatsappAction",
  props: {
    // Optional prop to pass your WhatsApp number or URL
    phoneNumber: {
      type: String,
      default: "8801716255538", // Replace with your default number
    },
    message: {
      type: String,
      default: "Hello, I would like to chat!",
    },
  },
  setup(props) {
    // Construct the WhatsApp URL using the provided phone number and message.
    // For more information on WhatsApp URL formatting, see: https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat
    const whatsappUrl = computed(() => {
      const baseUrl = "https://wa.me/";
      const encodedMessage = encodeURIComponent(props.message);
      return `${baseUrl}${props.phoneNumber}?text=${encodedMessage}`;
    });

    return { whatsappUrl , wp };
  },
});
</script>
