<template>
  <div class="container max-w-4xl mx-auto p-6 md:p-12 shadow-lg my-20 rounded-lg">
    <h1 class="text-3xl font-bold mb-4">Privacy Policy</h1>
    <section
      v-for="(section, index) in policySections"
      :key="index"
      class="mb-6"
    >
      <h2 class="text-xl font-semibold mb-2">{{ section.title }}</h2>
      <p
        v-for="(paragraph, pIndex) in section.content"
        :key="pIndex"
        class="text-gray-700 dark:text-gray-100 mb-2"
      >
        {{ paragraph }}
      </p>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      policySections: [
        {
          title: "Introduction",
          content: [
            "Welcome to PlayTuneMusic (“Company”, “we”, “our”, “us”). We respect your privacy and are committed to protecting it through this Privacy Policy. This policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.",
          ],
        },
        {
          title: "Information We Collect",
          content: [
            "Personal Information: Name, email address, phone number, payment details, and any other details you provide during account registration or service use.",
            "Music and Content Data: Uploaded songs, album artwork, metadata, and distribution preferences.",
            "Usage Data: Information about how you interact with our website and services, including IP addresses, browser type, and device information.",
            "Cookies and Tracking Technologies: We use cookies and similar technologies to enhance user experience and track website usage.",
          ],
        },
        {
          title: "How We Use Your Information",
          content: [
            "Providing, operating, and maintaining our distribution services.",
            "Processing payments and transactions.",
            "Communicating with you regarding updates, promotional offers, and customer support.",
            "Analyzing website usage to improve our platform and services.",
            "Complying with legal obligations and enforcing our terms of service.",
          ],
        },
        {
          title: "How We Share Your Information",
          content: [
            "We do not sell your personal data. However, we may share your information with:",
            "Distribution Partners: To distribute your music to platforms such as Spotify, Apple Music, and other streaming services.",
            "Service Providers: Third-party vendors assisting in payment processing, analytics, and security.",
            "Legal Compliance: If required by law or in response to legal proceedings.",
            "Business Transfers: In case of a merger, sale, or transfer of our assets.",
          ],
        },
        {
          title: "Data Security",
          content: [
            "We implement security measures to protect your personal data from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is completely secure.",
          ],
        },
        {
          title: "Your Rights",
          content: [
            "Access, update, or delete your personal information.",
            "Opt-out of marketing communications.",
            "Restrict or object to certain data processing activities.",
          ],
        },
        {
          title: "Third-Party Links",
          content: [
            "Our website may contain links to third-party services. We are not responsible for their privacy practices and encourage you to review their policies.",
          ],
        },
        {
          title: "Children’s Privacy",
          content: [
            "Our services are not intended for individuals under the age of 13, and we do not knowingly collect personal data from children.",
          ],
        },
        {
          title: "Changes to This Privacy Policy",
          content: [
            "We may update this policy from time to time. Any changes will be posted on our website with an updated revision date.",
          ],
        },
      ],
    };
  },
};
</script>

<style>
body {
  background-color: #f9fafb;
}
</style>
