<template>
  <div class="grid grid-cols-1 mt-6">
    <div class="tiny-three-item">
      <div
        v-for="(item, index) in clientdata"
        :key="index"
        class="tiny-slide text-center"
      >
        <div class="cursor-e-resize">
          <div
            class="content relative rounded shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left"
          >
            <i class="mdi mdi-format-quote-open mdi-48px text-red-500"></i>
            <p class="text-slate-400">{{ item.comments }}</p>
            <ul class="list-none mb-0 text-amber-400 mt-3"> 
              <li v-for="n in item.star" :key="n" class="inline">
                  <i class="mdi mdi-star"></i>
                </li>
            </ul>
          </div>

          <div class="text-center mt-5">
            <img
              :src="
                customerStore.appUrl +
                '/public'  + item.profile.file_url
                 
              "
              class="size-14 rounded-full shadow-md mx-auto"
              alt=""
            />
            <h6 class="mt-2 font-semibold">{{ item.name }}</h6>
            <span class="text-slate-400 text-sm">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end grid-->
</template>

<script setup>
import { onMounted, ref , computed } from "vue";
import { tns } from "tiny-slider";
 

import { useCustomerStore } from "@/stores/customerStore";
const customerStore = useCustomerStore();
 
const appURl = ref("https://backend-api-v2-dev.playtunemusic.com");
 
const clientdata = computed(() => customerStore.customers);

onMounted(async () => {
  await customerStore.fetchCustomers();
  // console.log("lientdata.value.length");
  // console.log(clientdata.value);
  // console.log(clientdata.value.length);


  tns({
    container: ".tiny-three-item",
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
      992: {
        items: 3,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  });
});
</script>