<template>
  <div>
    <Navbar :playBtn="false" :bg="true" />
    <disturabtionarea />
    <div
      v-for="(item, index) in StoreData"
      :key="index"
      class="container text-white p-8"
    >
      <div
        class="max-w-4xl mx-auto flex items-center justify-between border-b-[1px] pb-10 border-gray-400 dark:border-gray-200"
      >
        <div>
          <h1 class="text-4xl font-bold mb-4 text-black dark:text-white">
            {{ item.title }}
          </h1>
          <p class="text-lg mb-6 text-black dark:text-white">
            {{ item.description }}
          </p>

          <button
            class="bg-green-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-green-600 transition duration-300"
          >
            <a :href="item.route" target="_blank" >READ MORE</a>
          </button>
        </div>
        <div class="p-6">
          <img
            :src="StoreStore.appUrl +'/public'+ item.image.file_url"
            :alt="item.title"
            class="w-full h-full   p-1 m-6 border-2 dark:border-white border-red-500 rounded-lg"
          />
        </div>
      </div>
    </div>
    <Contact />
    <FooterOne />
    <ScrollToTop />
    <WhatsappAction />

    <!-- <Switcher /> -->
  </div>
</template>

<script setup>
import WhatsappAction from "@/components/WhatsappAction.vue";

import { ref, computed } from "vue";
import FooterOne from "@/components/footer-one.vue";
import Navbar from "@/components/navbar.vue";
import Contact from "@/components/contact.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";
import Switcher from "@/components/switcher.vue";
import disturabtionarea from "@/components/disturabtionareaTitle.vue";
import partner1 from "@/assets/partner/1707246729-apple-logo-white.svg";
import partner2 from "@/assets/partner/1707246819-tiktok-logo-white.svg";
import partner3 from "@/assets/partner/1707246830-youtube-logo-white.svg";
import partner5 from "@/assets/partner/spotify.svg";

const email = ref("");
const posts = ref([
  {
    title: "Spotify",
    summary:
      "  Spotify is currently the largest purely music-streaming platform in the world, with over 140 million active users per month enjoying a rich offering of popular playlists.",
    image: partner5,
  },
  {
    title: "Apple Music",
    summary:
      "Apple Music is currently the largest purely music-streaming platform in the world, with over 140 million active users per month enjoying a rich offering of popular playlists.",
    image: partner1,
  },
  {
    title: "Youtube Music",
    summary:
      "is currently the largest purely music-streaming platform in the world, with over 140 million active users per month enjoying a rich offering of popular playlists.",
    image: partner3,
  },
]);

const posts2 = ref([
  {
    title: "A Relentless Pursuit of Perfection",
    date: "19 Jan 2024",
    summary: "I began to notice a sharp contrast between...",
    image:
      "https://images.unsplash.com/photo-1738081359154-44d50176b2d0?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },

  {
    title: "How to Run a Successful Business With Your Partner",
    date: "18 Jan 2024",
    summary: "Starting a business with your spouse...",
    image:
      "https://images.unsplash.com/photo-1735908235870-f4dd182a2f12?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxMnx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "A Relentless Pursuit of Perfection",
    date: "19 Jan 2024",
    summary: "I began to notice a sharp contrast between...",
    image:
      "https://images.unsplash.com/photo-1738081359154-44d50176b2d0?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "How to Run a Successful Business With Your Partner",
    date: "18 Jan 2024",
    summary: "Starting a business with your spouse...",
    image:
      "https://images.unsplash.com/photo-1735149050406-76f49ced66e1?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "How to Run a Successful Business With Your Partner",
    date: "18 Jan 2024",
    summary: "Starting a business with your spouse...",
    image:
      "https://images.unsplash.com/photo-1735908235870-f4dd182a2f12?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxMnx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "A Relentless Pursuit of Perfection",
    date: "19 Jan 2024",
    summary: "I began to notice a sharp contrast between...",
    image:
      "https://images.unsplash.com/photo-1738081359154-44d50176b2d0?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "How to Run a Successful Business With Your Partner",
    date: "18 Jan 2024",
    summary: "Starting a business with your spouse...",
    image:
      "https://images.unsplash.com/photo-1735149050406-76f49ced66e1?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "How to Run a Successful Business With Your Partner",
    date: "18 Jan 2024",
    summary: "Starting a business with your spouse...",
    image:
      "https://images.unsplash.com/photo-1735908235870-f4dd182a2f12?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxMnx8fGVufDB8fHx8fA%3D%3D",
  },
]);

import Pagination from "@/components/Pagination.vue";
const items = Array.from({ length: 50 }, (_, i) => `Item ${i + 1}`);
const currentPage = ref(1);
const pageSize = 5;

const paginatedItems = computed(() => {
  const start = (currentPage.value - 1) * pageSize;
  // console.log(items.length);
  // console.log(items);

  return items.slice(start, start + pageSize);
});

function handlePageChange(page) {
  currentPage.value = page;
}

import { onMounted } from "vue";
import { useStoreStore } from "@/stores/store";
import { useGlobalStore } from "@/stores/globalStore";
const globalStore = useGlobalStore();

// Access the Pinia store
const StoreStore = useStoreStore();

// Fetch data when the component is mounted
onMounted(async () => {
  try {
    globalStore.setLoading(true); // Start loading
    await StoreStore.fetchStoreItems();
  } finally {
    globalStore.setLoading(false); // Stop loading
  }
});

// Reactive data from the store
const StoreData = computed(() => StoreStore.storeItems);
</script>

<style>
/* Tailwind will handle most styling */
</style>
