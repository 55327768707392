<template>
  <div>
    <Navbar :playBtn="false" :bg="true" />

    <!-- Start Hero -->
    <section class="relative table w-full py-24 overflow-hidden">
      <div class="container relative">
        <div
          class="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]"
        >
          <div class="md:col-span-6">
            <div class="md:me-6">
              <!-- <h6 class="mb-3 text-sm font-bold tracking-wider">#No1 Trending Apps On Play Store</h6> -->
              <h4
                class="font-extrabold lg:leading-normal leading-normal text-4xl lg:text-[54px] mb-5 text-transparent bg-clip-text animate-gradient"
                style="
                  background-image: linear-gradient(90deg, #ff5733, #001effff);
                "
              >
                {{
                  pageData[0] != null
                    ? pageData[0]?.title
                    : `Secure
                                Your Creative Rights`
                }}
              </h4>
              <p class="max-w-xl mx-auto text-lg text-slate-400">
                {{
                  pageData[0] != null
                    ? pageData[0]?.description
                    : `Protect your creative work
                                effortlessly.
                                Manage intellectual property with confidence.`
                }}
              </p>

              <div class="mt-6 mb-3 subcribe-form">
                <Button
                  type="submit"
                  size="sm"
                  class="h-11 px-5 text-[14px] tracking-wider inline-flex items-center justify-center font-medium rounded-2xl bg-red-500 text-white uppercase"
                >
                  {{
                    pageData[0] != null
                      ? pageData[0]?.button_title
                      : "Start now"
                  }}
                </Button>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="md:col-span-6">
            <div
              class="relative after:content-[''] after:absolute after:md:bottom-48 after:-bottom-20 lg:after:-start-10 md:after:-start-20 after:-start-24 after:bg-red-500 after:shadow-2xl after:shadow-red-500/40 after:z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[45rem] after:h-[30rem] after:rounded-[30rem]"
            >
              <img
                :src="
                  pageData[0] != null
                    ? `${
                        bannerPageStore.appUrl +
                        '/public' +
                        pageData[0].banner_image.file_url
                      }`
                    : `${phone}`
                "
                class="lg:max-w-[600px] md:max-w-md relative z-2 mover"
                alt=""
              />
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End Hero -->

    <!-- Business Partner -->
    <section
      class="py-6 border-t border-b border-gray-100 dark:border-gray-800"
    >
      <Partner />
    </section>
    <!--end section-->
    <!-- Business Partner -->

    <Banner_title
      title="Protecting your content, maximizing your revenue."
      description="Our comprehensive rights management service simplifies copyright protection and royalty collection for creators and rights holders. We monitor, track, and enforce your rights across platforms, ensuring you retain ownership and earn what you deserve. Focus on creating while we safeguard your work and optimize your content’s value."
    >
    </Banner_title>

    <ExtraFeature_right_bn />
    <ExtraFeature_left :banner="banner_1" />
    <disturabtionarea />

    <section
      class="relative py-16 md:py-24 bg-slate-50/50 dark:bg-slate-800/20"
      id="contact"
    >
      <Contact />
    </section>
    <!--end section-->
    <!-- End -->

    <FooterOne />
    <ScrollToTop />
    <WhatsappAction />

    <!-- <Switcher /> -->
  </div>
</template>
<style scoped>
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 3s ease infinite;
}
</style>
<script setup>
import WhatsappAction from "@/components/WhatsappAction.vue";

import phone from "@/assets/images/rights.png";
import banner_1 from "@/assets/images/my_image/music_service_banner_1.png";
import AboutOne from "@/components/about-one.vue";
import Button from "@/components/button.vue";
import Client from "@/components/client.vue";
import Contact from "@/components/contact.vue";
import Download from "@/components/download.vue";
import Faq from "@/components/faq.vue";

import FooterOne from "@/components/footer-one.vue";
import Navbar from "@/components/navbar.vue";
import Partner from "@/components/partner.vue";
import Promoshionfeture from "@/components/promoshionfeture.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";
import Switcher from "@/components/switcher.vue";
import Pricing from "@/components/pricing.vue";
import AboutTwo from "@/components/about-two.vue";

import ExtraFeature_left from "@/components/someExtra_bn_left.vue";
import ExtraFeature_right from "@/components/someExtra_bn_right.vue";
import ExtraFeature_right_bn from "@/components/someExtra_bn_rbn.vue";
import Banner_title from "@/components/Banner_title.vue";
import disturabtionarea from "@/components/disturabtionarea.vue";

import { computed, ref, onMounted } from "vue";
import { useBannerPageStore } from "@/stores/bannerPageStore";
import { useGlobalStore } from "@/stores/globalStore";
const globalStore = useGlobalStore();

// Access the Pinia store
const bannerPageStore = useBannerPageStore();

// Fetch data when the component is mounted
onMounted(async () => {
  try {
    globalStore.setLoading(true); // Start loading
    await bannerPageStore.fetchBannerPagesData();
  } finally {
    globalStore.setLoading(false); // Stop loading
  }
});

// Reactive data from the store
const bannerPagesData = computed(() => bannerPageStore.bannerPagesData);

// Filter data for the "home" page
const pageData = computed(() => {
  return bannerPagesData.value.filter(
    (page) => page.page_name === "right-management"
  );
});
</script>
