<template>
  <div>
    <Navbar :playBtn="false" :bg="true" />
    <Policy />
    <Contact />

    <!--end section-->
    <!-- End -->

    <FooterOne />
    <ScrollToTop />
    <WhatsappAction />

    <!-- <Switcher /> -->
  </div>
</template>
<style scoped>
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 3s ease infinite;
}
</style>
<script setup>

import WhatsappAction from "@/components/WhatsappAction.vue";
import phone from "@/assets/images/phone/lapop_homepahe3.png";
import Policy from "@/components/privecy_policy.vue";
import Client from "@/components/client.vue";
import CpPartner from "@/components/cp_partner.vue";

import Contact from "@/components/contact.vue";

import Download from "@/components/download.vue";
import Faq from "@/components/faq.vue";
import FeatureTwo from "@/components/feature-two.vue";
import FetcherOne from "@/components/feature-one.vue";
import FooterOne from "@/components/footer-one.vue";
import Navbar from "@/components/navbar.vue";
import Partner from "@/components/partner.vue";
import Promoshionfeture from "@/components/promoshionfeture.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";
import Switcher from "@/components/switcher.vue";
import Pricing from "@/components/pricing.vue";
import ExtraFeature_left from "@/components/someExtra_bn_left.vue";
import Extra_bn_right from "@/components/someExtra_bn_rbn.vue";

// State data Face

import { computed, ref, onMounted } from "vue";
import { useGlobalStore } from "@/stores/globalStore";

const globalStore = useGlobalStore();
</script>
