import IndexOne from "@/pages/index.vue";
import MusicDistribution from "@/pages/music_dristubation.vue";
import VideoPublish from "@/pages/video_publish.vue";
import Rights from "@/pages/rights_management.vue";
import Monetization from "@/pages/ty_Monetization.vue";
import Service from "@/pages/service.vue";
import Promotion from "@/pages/promotion.vue";
import Pricing from "@/pages/pricing.vue";
import Contact from "@/pages/contact.vue";
import Blog from "@/pages/blogPage.vue";
import Store from "@/pages/storePage.vue";
import BlogDetails from "@/pages/BlogDeatilsPage.vue";
import PrivacyPlicy from "@/pages/privacy_policyPage.vue";

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: IndexOne,
  },
  {
    path: "/service",
    name: "service",
    component: Service,
  },

  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPlicy,
  },
  {
    path: "/blog/:id",
    name: "BlogDetails",
    component: BlogDetails,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/store",
    name: "store",
    component: Store,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },
  {
    path: "/promotion",
    name: "promotion",
    component: Promotion,
  },
  {
    path: "/music-distribution",
    name: "MusicDistribution",
    component: MusicDistribution,
  },
  {
    path: "/video-distribution",
    name: "VideoDistribution",
    component: VideoPublish,
  },
  {
    path: "/rights",
    name: "Rights",
    component: Rights,
  },
  {
    path: "/monetization",
    name: "Monetization",
    component: Monetization,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // // Check if the route is the root ("/")
    // if (to.path === "/") {
    //   return undefined; // Do not scroll for the root route
    // }
    // // Scroll to the top for other routes
    return { top: 0 };
  },
});

export default router;
