<template>
  <div class="container relative">
    <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
      <div class="lg:col-span-5 md:col-span-6">
        <div
          class="pt-6 px-6 rounded-2xl bg-red-500/5 dark:bg-red-500/10 shadow shadow-red-500/20"
        >
          <img :src="phone" alt="" />
        </div>
      </div>
      <!--end grid-->

      <div class="lg:col-span-7 md:col-span-6">
        <div class="lg:ms-10">
          <h6
            class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3"
          >
            Mobile Apps
          </h6>
          <h4
            class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold"
          >
            Available for your <br />
            Smartphones
          </h4>
          <p class="text-slate-400 max-w-xl mb-0">
            Stay connected on the go! Our platform is fully optimized for
            smartphones, giving you seamless access anytime, anywhere. Enjoy a
            smooth, user-friendly experience designed for your convenience.
          </p>
          <div class="my-6">
            <!-- <router-link to=""  
              > -->
            <img :src="app" class="h-12 inline-block m-1" alt="" />
            <!-- </router-link> -->
            <!-- <router-link to=""
              > -->
            <img :src="play" class="h-12 inline-block m-1" alt="" />
            <!-- <
             /router-link> -->
          </div>

          <div class="inline-block">
            <div
              class="pt-4 flex items-center border-t border-gray-100 dark:border-gray-800"
            >
              <i
                data-feather="smartphone"
                class="me-2 text-red-500 size-10"
              ></i>
              <div class="content">
                <h6 class="text-base font-medium">
                  Install app now on your cellphones
                </h6>
                <div
                  class="hover:text-red-500 dark:hover:text-red-500 after:bg-red-500 dark:text-white transition duration-500 font-medium"
                >
                  Install Now <i class="mdi mdi-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end gird-->
  </div>
  <!--end container-->
</template>

<script setup>
import * as feather from "feather-icons";
import phone from "@/assets/images/phone/half-3.png";
import app from "@/assets/images/app.png";
import play from "@/assets/images/play.png";
import { onMounted } from "vue";

onMounted(() => {
  feather.replace();
});
</script>
