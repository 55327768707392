<template>
  <nav
    class="navbar"
    :class="[
      scroll ? 'is-sticky' : '',
      bg ? 'bg-white dark:bg-slate-900 shadow dark:shadow-gray-800' : '',
    ]"
    id="navbar"
  >
    <div class="container relative flex flex-wrap items-center justify-between">
      <router-link v-if="!navLight" class="navbar-brand md:me-8" to="/">
        <img :src="logoDark" class="inline-block h-8 dark:hidden" alt="" />
        <img :src="logoLight" class="hidden h-8 dark:inline-block" alt="" />
      </router-link>

      <div class="flex items-center nav-icons lg_992:order-2 md:ms-6">
        <ul class="menu-social">
          <li class="inline">
            <Button
              size="sm"
              class="px-4 text-[12px] bg-red-500 text-white uppercase"
            >
              Signup
            </Button>
          </li>
        </ul>
        <button
          type="button"
          class="collapse-btn ms-2 text-slate-900 dark:text-white lg_992:hidden"
          v-on:click="handler()"
        >
          <i class="mdi mdi-menu text-[24px]"></i>
        </button>
      </div>

      <div
        class="navigation lg_992:order-1 lg_992:flex"
        id="menu-collapse"
        :class="[toggle ? '' : 'hidden', navCenter ? '' : 'ms-auto']"
      >
        <ul class="navbar-nav float-left">
          <li
            v-for="menuItem in menuItems"
            :key="menuItem.id"
            class="nav-item relative group"
            :class="{ active: currentRoute === menuItem.path }"
          >
            <router-link
              class="nav-link"
              
              :to="menuItem.path"
              >{{ menuItem.label }}</router-link
            >

            <!-- Submenu -->
            <ul
              v-if="menuItem.subMenu"
              class="absolute hidden group-hover:flex text-black dark:text-white bg-white dark:bg-slate-900 shadow-md rounded-lg"
            >
              <li
                v-for="subItem in menuItem.subMenu"
                :key="subItem.id"
                class="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-800 whitespace-nowrap"
              >
                <router-link class="block w-full" :to="subItem.path">{{
                  subItem.label
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import Button from "@/components/button.vue";
import { useRoute } from "vue-router";
import logoDark from "@/assets/images/logo-dark.png";
import logoLight from "@/assets/images/logo-light.png";

const toggle = ref(false);
const scroll = ref(false);
const route = useRoute();
const currentRoute = ref("");
defineProps({
  navLight: Boolean,
  playBtn: Boolean,
  bg: Boolean,
  navCenter: Boolean,
});

const menuItems = [
  { id: "home", label: "Home", path: "/" },
  {
    id: "service",
    label: "Service",
    path: "/service",
    subMenu: [
      {
        id: "music-distribution",
        label: "Music Distribution",
        path: "/music-distribution",
      },
      {
        id: "video-distribution",
        label: "Video Distribution",
        path: "/video-distribution",
      },
      {
        id: "rights",
        label: "Rights Management",
        path: "/rights",
      },
      {
        id: "monetization",
        label: "YouTube Monetization",
        path: "/monetization",
      },
    ],
  },
  { id: "pricing", label: "Pricing", path: "/pricing" },
  { id: "promotion", label: "Promotion", path: "/promotion" },
  { id: "blog", label: "Blog", path: "/blog" },
];

const handleScroll = () => {
  scroll.value = window.scrollY >= 50;
};

const handler = () => {
  toggle.value = !toggle.value;
};

onMounted(() => {
  currentRoute.value = route.path;
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>
.nav-item:hover .nav-link {
  color: #e63946;
}

.nav-item .group:hover ul {
  display: flex;
}

.navbar-nav {
  display: flex;
  gap: 16px;
}

.nav-item ul {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 10;
}

.nav-item.active .nav-link {
  font-weight: bold;
  color: #e63946;
}
</style>
