import { defineStore } from "pinia";

export interface Features {
  id: number;
  description: string;
  button_title: string;
  created_id: number | null;
  created_at: string;
  updated_at: string;
}

export interface PricePlan {
  id: number;
  title: string;
  plan_type: string;
  description: string;
  monthly_price: string;
  yearly_price: string;
  active: number;
  see_all: number;
  features: Features[];
  created_at: string;
  updated_at: string;
}

export const priceStore = defineStore("priceStore", {
  state: () => ({
    PricePlanData: [] as PricePlan[],
    PriceFeaturesData: [] as Features[],
    isYearly: false,
    appUrl: process.env.VUE_APP_API_URL_MAIN,
  }),
  actions: {
    setSeeAll(tabId: number) {
      this.PricePlanData.find((item) => {
        if (item.id == tabId) {
          item.see_all = 1;
        } else {
          item.see_all = 0;
        }
      });
    },

    ChangeYearly() {
      this.isYearly = !this.isYearly;
      // console.log(this.isYearly);
    },

    async fetchPricePlanData() {
      // console.log(process.env.VUE_APP_API_URL);
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/price-plans"
        );
        const data = await response.json();
        // console.log(data);
        this.PricePlanData = data.data;
        // console.log("this.PricePlanData");
        // console.log(this.PricePlanData);
      } catch (error) {
        console.error("Error fetching banner pages data:", error);
      }
    },
    async fetchPriceFeaturesData() {
      // console.log(process.env.VUE_APP_API_URL);
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/features");
        const data = await response.json();
        // console.log(data);
        this.PriceFeaturesData = data.data.reverse();
        // console.log("this.PriceFeaturesData");
        // console.log(this.PriceFeaturesData);
      } catch (error) {
        console.error("Error fetching banner pages data:", error);
      }
    },
  },
});
