import { defineStore } from "pinia";

// Blog Image Interface
export interface BlogImage {
  id: number;
  filename: string;
  file_url: string;
  fileSize: string;
  minetype: string;
  is_active: number;
  created_id: number | null;
  created_at: string;
  updated_at: string;
}

// Blog Part Interface
export interface BlogPart {
  id: number;
  title: string;
  blogs: string;
  priority: number;
  image: number;
  created_at: string;
  updated_at: string;
  pivot: {
    blog_id: number;
    blog_part_id: number;
  };
  blog_image: BlogImage;
}

// Blog Interface
export interface BlogItem {
  id: number;
  headline: string;
  blogs: string;
  image: BlogImage;
  priority: number;
  views: number;
  created_at: string;
  updated_at: string;
  blog_parts: BlogPart[];
}

export const useBlogStore = defineStore("blog", {
  state: () => ({
    blogs: [] as BlogItem[],
    SingleBlogs: {} as BlogItem,
    isLoading: false as boolean,
    error: null as string | null,
    appUrl: process.env.VUE_APP_API_URL_MAIN,
  }),
  actions: {
    async fetchBlogs() {
      this.isLoading = true;
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL_MAIN + "api/blogs"
        );
        let d = await response.json();
        const data: BlogItem[] = d.data;
        this.blogs = data;
      } catch (error) {
        this.error = "Failed to fetch blog items";
      } finally {
        this.isLoading = false;
      }
    },

    async SingleBlogFatch(id: number) {
      this.isLoading = true;
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL_MAIN + `api/blogs/${id}`
        );
        let d = await response.json();
        // console.log("Single Blogs Data Get");
        // console.log(d);

        const data: BlogItem = d.data;
        this.SingleBlogs = data;
        // console.log("SingleBlogs");
        // console.log(this.SingleBlogs.blog_parts)

      } catch (error) {
        this.error = "Failed to fetch blog items";
      } finally {
        this.isLoading = false;
      }
    },
  },
});
