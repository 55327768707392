<template>
  <div>
    <Navbar :playBtn="false" :bg="true" />

    <div class="container">
      <section class="md:px-6 py-12">
        <div class="flex flex-col items-center justify-center p-20 px-4">
          <h1 class="text-3xl font-bold text-gray-900 dark:text-white mb-2">
            Stay Connected with Us
          </h1>
          <p class="text-gray-600 text-center mb-6">
            Subscribe to our newsletter and be the first to receive exclusive
            updates, special offers, and the latest news straight to your inbox.
            Sign up today and never miss a beat! We promise to keep your inbox
            inspiring, not overwhelming.
          </p>
          <div
            class="flex items-center space-x-2 w-full max-w-md border border-gray-400 dark:container rounded-lg focus:outline-none p-1"
          >
            <input
              type="email"
              v-model="email"
              placeholder="Enter your email"
              class="w-full p-1 dark:text-white dark:bg-slate-900 rounded-lg focus:outline-none focus:ring-black dark:focus:ring-white"
            />
            <button
            @click="sendEmail"
              class="bg-black dark:bg-white text-white dark:text-black px-3 py-2 text-sm md:text-md rounded-lg hover:bg-gray-800"
            >
              Subscribe
            </button>
          </div>
        </div>
      </section>

      <section class="md:px-6 md:py-8">
        <h2 class="text-2xl font-bold mb-6">Recent Blog Posts</h2>
        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          <article
            @click="goToBlog(firstBlog != null ? firstBlog.id : null)"
            class="col-span-2 row-span-3"
          >
            <div class="rounded-2xl shadow-lg overflow-hidden">
              <!-- Image Section -->
              <img
                class="w-full h-[550px] object-cover"
                :src="
                  firstBlog != null
                    ? BlogStore.appUrl + '/public' + firstBlog.image.file_url
                    : ''
                "
                alt="Person Image"
              />
              <!-- Content Section -->
              <div class="p-6">
                <div class="text-sm text-gray-700 dark:text-gray-300 mb-2">
                  {{
                    firstBlog != null ? formatDate(firstBlog.created_at) : ""
                  }}
                </div>

                <div class="head flex flex-row">
                  <h2
                    class="text-2xl text-black dark:text-white font-bold mb-2 line-clamp-2"
                  >
                    {{ firstBlog != null ? firstBlog.headline : "" }}
                  </h2>
                </div>

                <p class="text-gray-700 dark:text-gray-300 mb-4 line-clamp-5">
                  {{ firstBlog != null ? firstBlog.blogs : "" }}
                </p>
              </div>
            </div>
          </article>

          <article
            v-for="(post, index) in nextThreeBlogs"
            :key="index"
            @click="goToBlog(post != null ? post.id : null)"
            class="col-span-2 row-span-1"
          >
            <div
              class="mx-auto h-60 shadow-lg overflow-hidden flex flex-row rounded-lg"
            >
              <!-- Image Section -->
              <img
                class="w-1/2 object-cover rounded-lg my-2"
                :src="BlogStore.appUrl + '/public' + post.image.file_url"
                alt="Person Image"
              />

              <!-- Content Section -->
              <div class="p-6">
                <div class="text-sm text-gray-600 dark:text-gray-300 mb-2">
                  {{ formatDate(post.created_at) }}
                </div>

                <div class="head flex flex-row">
                  <h2
                    class="text-xl text-black dark:text-white font-bold mb-2 line-clamp-2"
                  >
                    {{ post.headline }}
                  </h2>
                </div>

                <p
                  class="text-gray-700 text-sm dark:text-gray-300 mb-4 line-clamp-5"
                >
                  {{ post.blogs }}
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>

      <!-- // All Blogs -->
      <section class="md:px-6 md:py-8 mt-10">
        <h2 class="text-2xl font-bold mb-6">All Blog Posts</h2>
        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          <article
            v-for="(post, index) in Blogsdata"
            :key="index"
            class="col-span-1"
            @click="goToBlog(post != null ? post.id : null)"
          >
            <div class="mx-auto shadow-lg overflow-hidden">
              <!-- Image Section -->
              <img
                class="h-40 w-full object-cover rounded-lg my-2"
                :src="BlogStore.appUrl + '/public' + post.image.file_url"
                alt="Person Image"
              />

              <!-- Content Section -->
              <div class="px-2">
                <div class="text-sm text-gray-600 dark:text-gray-300 mb-2">
                  {{ formatDate(post.created_at) }}
                </div>

                <h2
                  class="text-xl text-black dark:text-white font-bold mb-2 line-clamp-2"
                >
                  {{ post.headline }}
                </h2>
              </div>

              <p
                class="text-gray-700 text-sm dark:text-gray-300 mb-4 line-clamp-5 px-2"
              >
                {{ post.blogs }}
              </p>
            </div>
          </article>
        </div>
      </section>
      <div class="container mx-auto p-8">
        <!-- <h1 class="text-2xl font-bold mb-4">Paginated List</h1>

 
      <ul class="mb-4">
        <li v-for="item in paginatedItems" :key="item" class="border-b py-2">
          {{ item }}
        </li>
      </ul> -->

        <!-- Pagination Component -->
        <!-- <Pagination @page-changed="handlePageChange" /> -->
      </div>
    </div>
    <Contact />
    <FooterOne />
    <ScrollToTop />
    <WhatsappAction />

    <!-- <Switcher /> -->
  </div>
</template>

<script setup>
import WhatsappAction from "@/components/WhatsappAction.vue";

import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import FooterOne from "@/components/footer-one.vue";
import Navbar from "@/components/navbar.vue";
import Contact from "@/components/contact.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";
import Switcher from "@/components/switcher.vue";

import Pagination from "@/components/Pagination.vue";
const items = Array.from({ length: 50 }, (_, i) => `Item ${i + 1}`);
const currentPage = ref(1);
const pageSize = 5;

const paginatedItems = computed(() => {
  const start = (currentPage.value - 1) * pageSize;
  // console.log(items.length);
  // console.log(items);

  return items.slice(start, start + pageSize);
});

function handlePageChange(page) {
  currentPage.value = page;
}
const router = useRouter();

const goToBlog = (id) => {
  router.push({ name: "BlogDetails", params: { id } });
};
import { onMounted } from "vue";
import { useBlogStore } from "@/stores/blogStore";
import { useGlobalStore } from "@/stores/globalStore";
const globalStore = useGlobalStore();

// Access the Pinia store
const BlogStore = useBlogStore();

// Fetch data when the component is mounted
onMounted(async () => {
  try {
    globalStore.setLoading(true); // Start loading
    await BlogStore.fetchBlogs();
  } finally {
    globalStore.setLoading(false); // Stop loading
  }
});

// Reactive data from the store
const Blogsdata = computed(() => BlogStore.blogs);

const firstBlog = computed(() => BlogStore.blogs[0]);
const nextThreeBlogs = computed(() => BlogStore.blogs.slice(1, 4));

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options).replace(",", "");
}

import { useEmailStore } from "@/stores/EmailStore";

const emailStore = useEmailStore();

const email = ref(""); 

const sendEmail = async () => {
  // Basic email validation regex
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email.value || !emailPattern.test(email.value)) {
    alert("Please provide a valid email address.");
    return;
  }

  try {
    emailStore.isState = true;
    await emailStore.sendEmail({
      name: email.value.split("@")[0],
      email: email.value,
      question: "i want to subscribe on PlayTuneMusic blog",
    });
    email.value = ""; 
    emailStore.isState = false;
    alert("Subscribe successfully!");
  } catch (error) {
    console.error("Error to subscribe ", error);
    alert("Failed to Subscribe. Please try again.");
  }
};
</script>

<style>
/* Tailwind will handle most styling */
</style>
