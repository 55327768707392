import { defineStore } from 'pinia';

export interface StoreImage {
  id: number;
  filename: string;
  file_url: string;
  fileSize: string;
  minetype: string;
  is_active: number;
  created_id: number | null;
  created_at: string;
  updated_at: string;
}

export interface StoreItem {
  id: number;
  title: string;
  image: StoreImage;
  description: string;
  route: string;
  created_at: string;
  updated_at: string;
}

export const useStoreStore = defineStore('store', {
  state: () => ({
    storeItems: [] as StoreItem[],
    isLoading: false as boolean,
    error: null as string | null,
    appUrl:process.env.VUE_APP_API_URL_MAIN
  }),
  actions: {
    async fetchStoreItems() {
      this.isLoading = true;
      try {
        const response = await fetch(process.env.VUE_APP_API_URL+'/stores');
        let d = await response.json();
        const data: StoreItem[] = d.data;
        this.storeItems = data;
      } catch (error) {
        this.error = 'Failed to fetch store items';
      } finally {
        this.isLoading = false;
      }
    },
  },
});
