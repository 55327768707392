// stores/globalStore.ts
import { defineStore } from "pinia";

export const useGlobalStore = defineStore("globalStore", {
  state: () => ({
    isLoading: false as boolean, // Global loading state
    appUrl: process.env.VUE_APP_API_URL_MAIN,
  }),
  actions: {
    setLoading(status: boolean): void {
      this.isLoading = status; // Update loading state
    },
  },
});
