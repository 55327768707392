// stores/EmailStore.ts
import { defineStore } from "pinia";
import axios from "axios";

interface EmailPayload {
  name: string;
  email: string;
  question: string;
}

export const useEmailStore = defineStore("emailStore", {
  state: () => ({
    isState: false,
    appUrl: process.env.VUE_APP_API_URL_MAIN,
  }),

  actions: {
    async sendEmail(payload: EmailPayload): Promise<void> {
      try {
        // const response = await axios.post(process.env.VUE_APP_API_URL+"/contacts/send", payload);
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "/contacts",
          payload
        );

        // console.log("Email sent successfully", response.data);
      } catch (error) {
        console.error("Failed to send email:", error);
      }
    },
  },
});
