<template>
  <div class="container relative pb-32">
    <div class="grid grid-cols-1 pb-6 text-center">
      <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">
        Services
      </h6>

      <h4
        class="text-2xl md:text-3xl font-extrabold uppercase text-red-500 dark:text-red-500 pb-2"
      >
        Discover Powerful
        <span class="text-blue-500 dark:text-blue-500"> Services </span>
      </h4>

      <p class="text-slate-400 max-w-xl mx-auto">
        We provide a full suite of services to empower artists, labels, and
        content creators to thrive in the music industry.
      </p>
    </div>
    <!--end grid-->

    <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="p-6 transition duration-500 rounded-3xl"
        :class="
          item.active
            ? 'shadow-xl shadow-slate-100 dark:shadow-slate-800'
            : 'hover:shadow-xl hover:shadow-slate-100 border border-orange-200 dark:border-red-500 dark:hover:shadow-slate-800'
        "
      >
        <!-- Wrap the entire card content inside a router-link to make the whole card clickable -->
        <router-link :to="item.route" class="block">
          <div
            class="size-16 bg-red-500/5 text-red-500 rounded-2xl flex align-middle justify-center items-center shadow-sm"
          >
            <i :data-feather="item.icon" class="size-5"></i>
          </div>

          <div class="content mt-7">
            <p
              class="text-lg hover:text-red-500 dark:text-white dark:hover:text-red-500 transition-all duration-500 ease-in-out font-semibold"
            >
              {{ item.title }}
            </p>
            <p class="text-slate-400 mt-3">{{ item.desc }}</p>
          </div>
        </router-link>

        <!-- "Read More" button stays as a separate link with the same route -->
        <div class="mt-3">
          <router-link
            :to="item.route"
            class="hover:text-red-500 dark:hover:text-red-500 after:bg-red-500 dark:text-white transition duration-500"
          >
            Read More <i class="mdi mdi-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->
</template>

<script setup>
import { ref } from "vue";

const data = ref([
  {
    icon: "music",
    title: "Music Distribution",
    desc: "Manage and monetize your music across platforms effectively.",
    active: true,
    route: "/music-distribution",
  },
  {
    icon: "video",
    title: "Video Distribution",
    desc: "Streamline video content distribution and monetization.",
    active: false,
    route: "/video-distribution",
  },
  {
    icon: "check-circle",
    title: "Rights Management",
    desc: "Protect and manage intellectual property rights effortlessly.",
    active: true,
    route: "/rights",
  },
  {
    icon: "youtube",
    title: "YouTube Channel Monetization",
    desc: "Maximize revenue from your YouTube content with expert tools.",
    active: false,
    route: "/monetization",
  },
]);
</script>
