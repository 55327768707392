import { defineStore } from "pinia";

export interface BannerImage {
  id: number;
  filename: string;
  file_url: string;
  fileSize: string;
  minetype: string;
  is_active: number;
  created_id: number | null;
  created_at: string;
  updated_at: string;
}

export interface BannerPage {
  id: number;
  title: string;
  page_name: string;
  description: string;
  button_title: string;
  button_page_route: string;
  banner_image: BannerImage;
  created_at: string;
  updated_at: string;
}

export const useBannerPageStore = defineStore("bannerPage", {
  state: () => ({
    bannerPagesData: [] as BannerPage[],
    appUrl: process.env.VUE_APP_API_URL_MAIN,
  }),
  actions: {
    async fetchBannerPagesData() {
      // console.log(process.env.VUE_APP_API_URL);
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/banner-pages"
        );
        const data = await response.json();
        this.bannerPagesData = data.data;
        // console.log("this.bannerPagesData");
        // console.log(data.data);
        // console.log("this.bannerPagesData.length");
        // console.log(this.bannerPagesData.length);


      } catch (error) {
        console.error("Error fetching banner pages data:", error);
      }
    },
  },
});
